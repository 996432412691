const vehicle_load_capacity = {
    name: 'vehicle_load_capacity',
    title: 'Грузоподъемность',
    url: '/refs/vehicle_load_capacity',
    perms: {
        list: 'v1.vehicle_load_capacity.list',
        read: 'v1.vehicle_load_capacity.read',
        create: 'v1.vehicle_load_capacity.create',
        update: 'v1.vehicle_load_capacity.update',
        delete: 'v1.vehicle_load_capacity.delete',
    },
    listColumns: [
        {
            name: 'weight',
            required: true,
            label: 'Грузоподъемность',
            align: 'left',
            field: 'weight',
            sortable: true,
        },
        {
            name: 'vehicle_axes',
            required: true,
            label: 'Количество осей ТС',
            align: 'left',
            field: 'vehicle_axes',
            sortable: true,
        },
        {
            name: 'trailer_axes',
            required: true,
            label: 'Количество осей прицепа',
            align: 'left',
            field: 'trailer_axes',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'weight',
            required: true,
            label: 'Грузоподъемность',
            align: 'left',
            field: 'weight',
            sortable: true,
        },
        {
            name: 'vehicle_axes',
            required: true,
            label: 'Количество осей ТС',
            align: 'left',
            field: 'vehicle_axes',
            type: 'number',
        },
        {
            name: 'trailer_axes',
            required: true,
            label: 'Количество осей прицепа',
            align: 'left',
            field: 'trailer_axes',
            type: 'number',
        },
    ],
};

export {
    vehicle_load_capacity,
};
